import axios from "axios";
import { Message } from "element-ui";
import router from './router/index'

let Axios = axios.create({});

Axios.defaults.baseURL = 'https://api.huiyan-tech.com/platform/v1' // 正式环境
// Axios.defaults.baseURL = 'https://api.tmp.huiyan-tech.com/platform/v1' // 预发布环境
// Axios.defaults.baseURL = 'https://api.voice.dolphin-ai.jp/platform/v1' // 日本环境

Axios.defaults.headers.post["Content-Type"] =
  "application/json;charset=UTF-8;";

window._axiosPromiseArr = []
// 请求拦截器
Axios.interceptors.request.use(
  config => {
    config.cancelToken = new axios.CancelToken(cancel => {
      window._axiosPromiseArr.push({ cancel })
    })
    return config;
  },
  error => {
    return Promise.error(error);
  }
);

// 响应拦截器
Axios.interceptors.response.use(
  response => {
    if (response.status === 200) {
      switch (response.data.status) {
        case "000000":
          return Promise.resolve(response.data)
        case "100002":
          Message.error(response.data.message)
          window.sessionStorage.removeItem('username')
          window.localStorage.removeItem('sessionID')
          router.push('/login')//退出登录
          break;
        default:
          if (response.data.status) {
            if (response.request.responseURL !== Axios.defaults.baseURL + '/' && response.request.responseURL !== Axios.defaults.baseURL + '/chapter') { // 既不是登录请求也不是访问服务器地址
              Message.error(response.data.message);
            }
            return Promise.reject(response.data);
          } else {
            return Promise.resolve(response);
          }
      }
    }
  },
  // 服务器状态码不是200的情况
  error => {
    if (error.message.includes('timeout')) {
      Message.error('请求超时，请稍后重试。');
      return Promise.reject(error);
    }
    if (error.response) {
      switch (error.response.status) {
        // 404 请求不存在
        case 404:
          Message.error("网络请求不存在");
          break;
        // 其他错误，直接抛出错误提示
        default:
          if (error.response.message) {
            Message.error(error.response.message);
          } else {
            Message.error('请求失败');
          }
      }
      return Promise.reject(error.response);
    } else {
      window.sessionStorage.removeItem('username')
      window.localStorage.removeItem('sessionID')
      router.push('/login')//退出登录
      if (error.message) {
        Message.error('请求失败');
      } else {
        return Promise.reject(error);
      }
    }
  }
);

export default Axios;
