import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale';
import lang from 'element-ui/lib/locale/lang/ja'; // 日语语言包
import './assets/css/reset.css'
import './assets/css/common.css'
import '@/assets/js/rem'
import txt from '@/assets/json/txt.json'
// axios
import Axios from "./Axios"
Vue.prototype.$Axios = Axios
// 路由拦截
// import RouterInterceptor from "./RouterInterceptor"
// RouterInterceptor(router)

// 设置 ElementUI 使用日语语言
locale.use(lang);
Vue.use(ElementUI)

Vue.config.productionTip = false

Vue.directive('title', {
  inserted: function (el, binding) {
    document.title = el.dataset.title
  }
})

// 文案内容
Vue.prototype.globalTxt = txt.zh
// Vue.prototype.globalTxt = txt.ja

// 提示信息
Vue.prototype.globalMsgTxt = txt.zh_msg
// Vue.prototype.globalMsgTxt = txt.en_msg

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
