import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import('../components/Overview.vue'),
      },
      {
        path: '/personality',
        name: 'Personality',
        component: () => import('../components/Personality.vue'),
      },
      {
        path: '/monitoring',
        name: 'Monitoring',
        component: () => import('../components/Monitor.vue'), 
      },
      {
        path: '/license',
        name: 'License',
        component: () => import('../components/License.vue'),
      },
      {
        path: '/authorization',
        name: 'Authorization',
        component: () => import('../components/Authorization.vue'), 
      },
      {
        path: '/whiteList',
        name: 'WhiteList',
        component: () => import('../components/WhiteList.vue'),
      },
      {
        path: '/personalcenter',
        name: 'PersonalCenter',
        component: () => import('../components/PersonalCenter.vue'), 
      },
      {
        path: '/errorstatistics',
        name: 'Error',
        component: () => import('../components/Error.vue'), 
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'), 
  }
]

const router = new VueRouter({
  routes
})

export default router
